import { get, post, put } from "@/api/axios";
import { baseUrl } from "../config/public";
//过滤接口路径
export function jointQuery(url, params) {
  // 是否携带query
  const query = Object.keys(params)
    .filter((key) => ![undefined, null].includes(params[key])) // 排除掉无效值
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");

  if (!query) {
    return url;
  }
  return url + (url.search(/\?/) === -1 ? "?" : "&") + query;
}

//导出方法
export const exportExcel = async function(url = "", params = {}, Token = "") {
  if (!url) {
    return;
  }
  const Authorization = Token;
  try {
    const headers = new Headers();
    if (Authorization) {
      headers.set("Authorization", Authorization);
    }

    const fetchUrl = jointQuery(baseUrl + url, {
      ...params,
      pageNum: 1,
      pageSize: undefined,
    });
    const res = await fetch(fetchUrl, {
      method: "GET",
      headers,
      responseType: "blob",
    });

    const blobData = await res.blob();

    let blob = new Blob([blobData], {
      type: "application/vnd.ms-excel; charset=UTF-8",
    });
    // 创建下载的链接
    let downloadElement = document.createElement("a");
    let href = window.URL.createObjectURL(blob);
    downloadElement.href = href;

    const contentDisposition = res.headers.get("content-disposition") || "";
    const [_, fileName = ""] = contentDisposition.split("filename=");
    downloadElement.download = decodeURI(fileName) || ""; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
  } catch (error) {}
  //   get(url, { ...params,responseType: 'blob'})
  //     .then((res) => {
  //       console.log("返回的二进制流文件", res);

  //       if (!res) return;

  //       //fileName:下载的文件名
  //         console.log(res.headers,'xxxxxxxxx');
  //     //   var fileName = decodeURIComponent(
  //     //     res.headers['Content-Disposition'].split("=")[1]
  //     //   );
  //     var fileName ='文件'

  //     // let blob = new Blob([res], { type: 'application/vnd.ms-excel; charset=UTF-8' })
  //     // 创建下载的链接
  //     let downloadElement = document.createElement('a')
  //     let href = window.URL.createObjectURL(blob)
  //     downloadElement.href = href

  //     const contentDisposition = 'filename=测试'
  //     // const [_, fileName = ''] = contentDisposition.split('filename=')
  //     downloadElement.download = decodeURI(fileName) || '' //下载后文件名
  //     document.body.appendChild(downloadElement)
  //     downloadElement.click() //点击下载
  //     document.body.removeChild(downloadElement) //下载完成移除元素
  //     window.URL.revokeObjectURL(href) //释放掉blob对象
  //     })
  //     .catch(function(error) {
  //       console.log(error);
  //     });
};
